import http from '../../../../utils/services/http'

export const list = (limit, page, filtros, basic) => {
  let url = '/api/produtos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/produtos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newProduto = (data) => {
  let url = `/api/produtos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateProduto = (id, data, method = 'PUT') => {
  let url = `/api/produtos/${id}`
  let m = http.put
  if (method === 'PATCH') {
    m = http.patch
  }
  return m(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const remove = (id) => {
  let url = `/api/produtos/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateFotoProduto = (id, data) => {
  let url = `/api/produtos/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const setup = (id) => {
  let url = `/api/produtos/${id}/setup`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const dashboardProdutos = () => {
  let url = `/api/produtos/dashboard`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
